import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UsersTableState {
  pageNumber: number;
  pageSize: number;
  searchTerm: string;
}

const initialState: UsersTableState = {
  pageNumber: 1,
  pageSize: 10,
  searchTerm: '',
};

const usersTableSlice = createSlice({
  name: 'usersTable',
  initialState,
  reducers: {
    setPageNumber(state, action: PayloadAction<number>) {
      state.pageNumber = action.payload;
    },
    setPageSize(state, action: PayloadAction<number>) {
      state.pageSize = action.payload;
    },
    setSearchTerm(state, action: PayloadAction<string>) {
      state.searchTerm = action.payload;
    },
  },
});

export const { setPageNumber, setPageSize, setSearchTerm } = usersTableSlice.actions;

export const { reducer: usersTableReducer } = usersTableSlice;
