import { combineReducers } from '@reduxjs/toolkit';
import { marketPricesReducer } from '../features/asset-master/asset-details/market-prices/marketPricesSlice';
import { curveDetailsReducer } from '../features/curves/curve-details/curveDetailsSlice';
import { curveListReducer } from '../features/curves/curve-list/curveListSlice';
import { organizationsTableReducer } from '../features/organizations/organizations-table/organizationsTableSlice';
import { usersTableReducer } from '../features/users/users-table/usersTableSlice';

export const rootReducer = combineReducers({
  curveList: curveListReducer,
  curveDetails: curveDetailsReducer,
  marketPrices: marketPricesReducer,
  usersTable: usersTableReducer,
  organizationsTable: organizationsTableReducer,
});
