import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface OrganizationsTableState {
  pageNumber: number;
  pageSize: number;
  searchTerm: string;
}

const initialState: OrganizationsTableState = {
  pageNumber: 1,
  pageSize: 10,
  searchTerm: '',
};

const organizationsTableSlice = createSlice({
  name: 'organizationsTable',
  initialState,
  reducers: {
    setPageNumber(state, action: PayloadAction<number>) {
      state.pageNumber = action.payload;
    },
    setPageSize(state, action: PayloadAction<number>) {
      state.pageSize = action.payload;
    },
    setSearchTerm(state, action: PayloadAction<string>) {
      state.searchTerm = action.payload;
    },
  },
});

export const { setPageNumber, setPageSize, setSearchTerm } = organizationsTableSlice.actions;

export const { reducer: organizationsTableReducer } = organizationsTableSlice;
